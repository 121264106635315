import { Button, Typography } from 'antd';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { useStore } from '../../hooks/use-store';
import Section from '../Section';

const { Title } = Typography;

interface Site {
  id: string;
  siteMetadata?: {
    title: string;
    description: string;
  };
}

const LogoSection = ({ children, to }) => {
  return (
    <Link
      to={to}
      className="bg-white m-1.5 rounded-[8px] w-20 h-12 md:w-[182px] md:h-[104px] p-1.5 md:p-6 border-light-divider shadow-small inline-block"
    >
      {children}
    </Link>
  );
};

const Banner = ({ site }: { site: Site }) => {
  const { store } = useStore();
  return (
    <div className="w-full relative">
      <StaticImage
        src="../../images/banner.jpeg"
        alt="banner"
        placeholder="tracedSVG"
        className="!absolute md:!relative h-full w-full md:h-[888px]"
        objectFit="cover"
      />
      <div className="relative md:absolute md:h-full w-full top-0 left-0 bg-gradient-to-b from-background-start to-background-end">
        <Section className="flex flex-col h-full">
          <div className="flex flex-1 flex-col mb-12 items-center justify-center">
            <Title className="!text-dark-title !mb-4 text-center">
              {store?.title}
            </Title>
            <Title
              className="!text-dark-title !mb-8 mt-0 text-center !font-normal"
              level={4}
            >
              {store?.subtitle}
            </Title>
            <Link to="/products">
              <Button type="primary" className="!px-6" size="large" block>
                สินค้าทั้งหมด
              </Button>
            </Link>
          </div>
          <div className="w-[calc(100% + 12px)] mx-[-6px] text-center">
            <LogoSection to="/">
              <StaticImage
                src="../../images/antonio-masiero.png"
                alt="Antonio Masiero"
                placeholder="tracedSVG"
                className="w-full h-full"
                objectFit="contain"
              />
            </LogoSection>
            <LogoSection to="/">
              <StaticImage
                src="../../images/euroricambi.png"
                alt="Euroricambi"
                placeholder="tracedSVG"
                className="w-full h-full"
                objectFit="contain"
              />
            </LogoSection>
            <LogoSection to="/">
              <StaticImage
                src="../../images/thai-yamato.png"
                alt="Thai Yamato"
                placeholder="tracedSVG"
                className="w-full h-full"
                objectFit="contain"
              />
            </LogoSection>
            <LogoSection to="/">
              <StaticImage
                src="../../images/tsa.png"
                alt="TSA"
                placeholder="tracedSVG"
                className="w-full h-full"
                objectFit="contain"
              />
            </LogoSection>
            <LogoSection to="/">
              <StaticImage
                src="../../images/tsa-group-copyright.png"
                alt="TSA Group"
                placeholder="tracedSVG"
                className="w-full h-full"
                objectFit="contain"
              />
            </LogoSection>
            <LogoSection to="/">
              <StaticImage
                src="../../images/hnec.png"
                alt="HNEC"
                placeholder="tracedSVG"
                className="w-full h-full"
                objectFit="contain"
              />
            </LogoSection>
            <LogoSection to="/">
              <StaticImage
                src="../../images/nknk.png"
                alt="NKNK"
                placeholder="tracedSVG"
                className="w-full h-full"
                objectFit="contain"
              />
            </LogoSection>
          </div>
        </Section>
      </div>
    </div>
  );
};

export default Banner;
