import { Button, Typography } from 'antd';
import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { useCustomer } from '../../hooks/use-customer';
import { useProducts } from '../../hooks/use-products';
import { Customer } from '../../types/Customer';
import { Product } from '../../types/Product';
import Section from '../Section';
import { ProductCardList } from './ProductList';

const { Title } = Typography;

const LatestProduct = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const { customer: rawCustomer } = useCustomer();
  const customer = rawCustomer as Customer | undefined;
  const {
    actions: { listLatestProduct },
  } = useProducts();
  const [loading, setLoading] = useState(false);

  const fetchProducts = async () => {
    try {
      setLoading(true);
      const res = await listLatestProduct({ limit: 12 });
      setProducts(res);
    } catch (_) {
    } finally {
      setLoading(false);
    }
  };

  const isApproved = customer?.metadata?.status === 'approved';
  useEffect(() => {
    if (isApproved) {
      fetchProducts();
    }
  }, [customer]);

  if (!isApproved) return null;
  return (
    <Section className="flex flex-col items-center">
      <Title level={2} className="!mb-12">
        สินค้าล่าสุด
      </Title>
      <ProductCardList products={products} loading={loading} loadingSize={12} />
      <Link to={'/products'}>
        <Button className="!text-primary-6 mt-12">ดูสินค้าทั้งหมด</Button>
      </Link>
    </Section>
  );
};

export default LatestProduct;
