import { useContext } from 'react';
import StoreContext from '../context/store-context';

export const useStore = () => {
  const context = useContext(StoreContext);

  if (!context) {
    throw new Error(
      'useStore hook was used but a StoreContext.Provider was not found in the parent tree. Make sure this is used in a component that is a child of StoreProvider'
    );
  }

  return context;
};
