import { Button, Typography } from "antd"
import { Link } from "gatsby"
import React from "react"
import { useCollection } from "../../hooks/use-collection"
import { filterParentCollections } from "../../utils/collectionUtil"
import CollectionCard from "../collection/CollectionCard"
import Section from '../Section'

const { Title, Text } = Typography


const ExploreCollections = () => {
  const { collections } = useCollection()
  if (!collections) return null
  const parentCollections = filterParentCollections(collections)
  return <Section className="flex flex-col items-center">
    <Title level={2} className="!mb-12">ประเภทสินค้า</Title>
    <div className="grid gap-3 grid-cols-2 md:grid-cols-4 lg:grid-cols-6 w-full">
      {parentCollections.map((collection) => (
        <CollectionCard collection={collection} />
      ))}
    </div>
    <Link to={'/collections'} className="mt-12">
      <Button className="!text-primary-6">ดูประเภทสินค้าทั้งหมด</Button>
    </Link>
  </Section>
}

export default ExploreCollections
