import MainLayout from '../components/layout/MainLayout';
import React from 'react';
import SearchEngineOptimization from '../components/utility/seo';
import { graphql } from 'gatsby';
import Banner from '../components/home/Banner';
import ExploreCollections from '../components/home/ExploreCollections';
import LatestProduct from '../components/products/LatestProducts';

const Spacer = () => <div class="py-0 md:py-8"></div>;

const IndexPage = ({ data }) => {
  const site = data.site;
  return (
    <MainLayout>
      <SearchEngineOptimization title="Home" />
      <Banner site={site} />
      <Spacer />
      <LatestProduct />
      <ExploreCollections />
      <Spacer />
    </MainLayout>
  );
};

export const query = graphql`
  query {
    site {
      id
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;

export default IndexPage;
